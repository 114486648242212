$primaryButtonColor: #0d6efd;

.map-modal-container{
    .modal-dialog{
        max-width: 800px;
        .branches-list{
            margin-top: 3rem;
        }
    }
    .address-readonly{
        margin-bottom: 10px;
    }
    .btn-primary{
        background: $primaryButtonColor;
        border-color: $primaryButtonColor;

        &:hover{
            background: $primaryButtonColor;
            border-color: $primaryButtonColor;
        }
    }
    .disabled{
        opacity: 0.8;
        cursor: not-allowed;
    }
}
.pac-container{
    z-index: 1500;
}
.react-confirm-alert-overlay{
    z-index: 1111 !important;
    & h1{
        font-size: 2rem !important;
    }
}