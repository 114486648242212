.container {
  height: 100vh;
  width: 100%;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;

  & div {
    position: absolute;
    top: 50%;
  }
}
