@import url("https://fonts.googleapis.com/css2?family=Mulish:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("./bootstrap-variables.scss");

* {
  font-family: "Mulish", sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700 !important;
}

input,
textarea,
select {
  &:focus,
  &:focus-visible {
    box-shadow: none !important;
    border-color: black !important;
  }
}

.form-check-input:checked {
  background-color: #ef4745 !important;
  border-color: #ef4745 !important;
  -webkit-box-shadow: inset 0px 0px 0px 2.5px #fff !important;
  -moz-box-shadow: inset 0px 0px 0px 2.5px #fff !important;
  box-shadow: inset 0px 0px 0px 2.5px #fff !important;
}

button {
  font-size: 0.9rem !important;
  color: white;
  font-weight: 600 !important;
  &:focus,
  &:focus-visible {
    box-shadow: none !important;
  }
}

.navbar {
  min-height: 88px;
}

.navbar-light .navbar-nav .nav-link {
  color: #373f41;
  font-weight: 400;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link {
  color: #ffffff;
}

.cursor-pointer {
  cursor: pointer;
}

.line-clamp-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-clamp-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

// .form-check-input:checked {
//   background-color: #ef4745 !important;
//   border-color: #ef4745 !important;
// }

.btn-outline-primary {
  color: #ef4745 !important;
  &:hover {
    color: white !important;
  }
}

.btn-outline-dark {
  color: #212529;
  border-color: #212529;
  &:hover {
    color: white;
  }
}

.place-items-center {
  place-items: center;
}

input[type="password"] {
  &::placeholder {
    font-size: 1rem;
    position: relative;
    top: 0.2rem;
  }
}

.PhoneInput {
  input {
    border-left: none !important;
    border-top-right-radius: 0.25rem;
    border-bottom-right-radius: 0.25rem;
  }

  .PhoneInputCountry {
    padding-left: 0.5rem;
    border: 1px solid #ced4da;
    border-right: none !important;
    margin-right: 0 !important;
    border-top-left-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }
}

.PhoneInput--focus {
  .PhoneInputCountry {
    border-color: #000000 !important;
  }
}

label {
  font-size: 0.9rem;
}
input,
select,
textarea {
  outline: none !important;
  box-shadow: none !important;
  font-size: 0.9rem;

  &::placeholder {
    font-size: 0.9rem;
  }
}

.no-bg-on-hover {
  &:hover {
    background: rgba(245, 245, 245, 0.925) !important;
    color: #ef4745 !important;
  }
}

.deals-right-arrow {
  & path {
    fill: unset;
  }
}

.filter_display_none{
  display: none;
  opacity: 0;
  // animation-name: cssAnimationNone;
  // animation-duration:1s;
  // animation-iteration-count: 1;
  // animation-timing-function: all ease;
  // animation-fill-mode: forwards;
}

@keyframes cssAnimationNone {
  0% {
    opacity: 1;
    margin-top: 0px;
  }
  50% {
    opacity: 0.5;
    margin-top: -20px;
  }
  75% {
    opacity: 0.25;
    margin-top: -30px;
  }
  100% {
    opacity: 0;
    margin-top: -50px;
    display: none;
    z-index: -1;
  }
}

.filter_display_flex{
  // animation-name: cssAnimationDisplay;
  // animation-duration: 0.5s;
  // animation-timing-function: all ease;
  // animation-fill-mode: forwards;
  opacity: 1;
  display: flex;
}

@keyframes cssAnimationDisplay {
  0% {
    opacity: 0;
    margin-top: -30px;
  }
  25% {
    opacity: 0.25;
    margin-top: -25px;
  }
  50% {
    opacity: 0.5;
    margin-top: -20px;
  }
  75% {
    opacity: 0.75;
    margin-top: -10px;
  }
  100% {
    opacity: 1;
    margin-top: 23px;
  }
}

.footer-social-links{
  span{
    float: left;
  }
}

.powered-by-theme3{
  font-size: 17px;
  line-height: 24px;

  span{
    padding: 10px;
  }

  svg path{
    fill: unset;
  }
}